<template>
    <div style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
        <vue-html2pdf
            :show-layout="false"
            :filename="SalesOrder__title"
            :float-layout="true"
            :enable-download="false"
            :html-to-pdf-options="htmlToPdfOptions"
            :paginate-elements-by-height="1120"
            :manual-pagination="true"
            :pdf-quality="1"
            :preview-modal="false"
            pdf-format="a4"
            pdf-content-width="750px"
            ref="html2Pdf"
            @beforeDownload="beforeDownload($event)"
        >
            <section slot="pdf-content">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <div class="d-flex flex-column fill-height">
                            <v-col class="pa-0 ma-0 mb-auto">
                                <v-row no-gutters>
                                    <v-col class="col-9-5 d-flex flex-column pt-3">
                                        <div>
                                            <span class="header-title">{{ Office__title  }}</span>
                                            <span class="header-subtitle font-weight-bold" style="vertical-align: top">{{ '(' + Office__companyno + ')' }}</span>
                                        </div>
                                        <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                                        <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(word => word != null && word != '').join(', ')  }}</span>
                                        <span class="header-subtitle pt-1 pb-0" v-if="Office__contracttel != null">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                                        <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__invoiceemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                                    </v-col>
                                    <v-col class="col-2-5 text-right">
                                        <img :src="appLogo" class="mb-3 mr-3" width="130px"/>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ $t('message.proformaInvoice') }}</v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <v-row no-gutters>
                                            <v-col cols="3" class="font-weight-bold">{{ $t('message.buyer') }}:</v-col>
                                            <v-col cols="8" class="d-flex flex-column">
                                                <span>{{ Customer__title }}</span>
                                                <span>{{ Customer__address1 }}</span>
                                                <span>{{ Customer__address2 }}</span>
                                                <span>{{ Customer__address3 }}</span>
                                                <span>{{ Customer__city + ' ' + Customer__postcode}}</span>
                                                <span>{{ Customer__state }}</span>
                                                <span>{{ Customer__country }}</span>
                                                <span class="text-no-wrap">{{ getTaxId() }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="3" class="font-weight-bold">{{ $t('message.attn') }}:</v-col>
                                            <v-col cols="8">{{ Contact__prefix ? Contact__prefix + ' ' : '' }}{{ Contact__name ? Contact__name : $t('message.na').toUpperCase() }}</v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="3" class="font-weight-bold">{{ $t('message.tel') }}:</v-col>
                                            <v-col cols="8">{{ Contact__tel ? Contact__tel : $t('message.na').toUpperCase() }}</v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="3" class="font-weight-bold">{{ $t('message.hp') }}:</v-col>
                                            <v-col cols="8">{{ Contact__hp ? Contact__hp : $t('message.na').toUpperCase() }}</v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="3" class="font-weight-bold">{{ $t('message.email') }}:</v-col>
                                            <v-col cols="8">{{ Contact__email ? Contact__email : $t('message.na').toUpperCase() }}</v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="col-4-5">
                                        <v-row no-gutters>
                                            <v-col cols="5" class="font-weight-bold">{{ $t('message.paymentTerms') }}:</v-col>
                                            <v-col cols="7">{{ Paymentstatement__title }}</v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="5" class="font-weight-bold">{{ $t('message.deliveryTerms') }}:</v-col>
                                            <v-col cols="7">{{ SalesOrder__json.delivery_terms }}</v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="5" class="font-weight-bold">{{ $t('message.releaseDate') }}:</v-col>
                                            <v-col cols="7">{{ formatDate(SalesOrder__pickup_date) }}</v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="col-3-5">
                                        <v-row no-gutters>
                                            <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.date') }}:</v-col>
                                            <v-col cols="6">{{ SalesOrder__sodate ? formatDate(SalesOrder__sodate) : '' }}</v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.piNo') }}:</v-col>
                                            <v-col cols="6">{{ SalesOrder__title.replace('SO','PI') }}</v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.marketing') }}:</v-col>
                                            <v-col cols="6">{{ Salescontact__name }}</v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="px-0 pb-0 pt-3 ma-0 mb-auto">
                                <v-simple-table class="specification-table" dense>
                                    <template v-slot:default>
                                        <tr class="table-header">
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-15-pct">{{ $t('message.specification') }}</td>
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1">{{ $t('message.size') }}</td>
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1">{{ $t('message.grade') }}</td>
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-1-pct">{{ $t('message.pcs') }}</td>
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-10-pct">{{ $t('message.quantity') }}</td>
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-10-pct">
                                                <div class="d-flex flex-column">
                                                    <span>{{ $t('message.unitPrice') }}</span>
                                                    <span class="mr-3">USD</span>
                                                </div>
                                            </td>
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-10-pct">
                                                <div class="d-flex flex-column">
                                                    <span>{{ $t('message.total') }}</span>
                                                    <span>USD</span>
                                                </div>
                                            </td>
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-10-pct">
                                                <div class="d-flex flex-column">
                                                    <span>{{ $t('message.price') }}</span>
                                                    <span v-if="SalesOrder__currency_id == 2">{{  getCurrencyCode(Office__currency_id) }}</span>
                                                    <span v-else>{{ getCurrencyCode(SalesOrder__currency_id) }}</span>
                                                </div>
                                            </td>
                                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-10-pct">
                                                <div class="d-flex flex-column">
                                                    <span>{{ $t('message.total') }}</span>
                                                    <span v-if="SalesOrder__currency_id == 2">{{ getCurrencyCode(Office__currency_id) }}</span>
                                                    <span v-else>{{ getCurrencyCode(SalesOrder__currency_id) }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <template v-for="(item,index) in SalesOrderItems">
                                            <tr class="table-row-main" v-if="index == 0 && item.type == 'default'">
                                                <td colspan="8">{{ getItemDescription(item) }}</td>
                                            </tr>
                                            <tr class="table-row" v-else-if="index > 0 && getItemDescription(SalesOrderItems[index - 1]) != getItemDescription(item) && item.type == 'default'">
                                                <td colspan="8">{{ getItemDescription(item) }}</td>
                                            </tr>
                                            <tr class="table-row" v-if="item.type == 'default'">
                                                <td class="px-0"></td>
                                                <td class="px-0 text-no-wrap">{{ item.Stock.size }}</td>
                                                <td class="px-0 text-no-wrap">{{ item.Grade.title }}</td>
                                                <td class="px-0">{{ item.Stock.piece }}</td>
                                                <td class="px-0 text-right text-no-wrap">{{ formatThisNumber(item.Stock.volume, uofmPrecision(item.Soldmeasurement.title)) + " " + item.Soldmeasurement.title }}</td>
                                                <td class="px-0 text-right">
                                                    <span v-if="SalesOrder__currency_id == 2">{{ formatThisNumber( item.Stock.sellingprice, '0,0.00' ) }}</span>
                                                    <span v-else>{{ formatThisNumber( (item.Stock.sellingprice / SalesOrder__gstrmrate), '0,0.00' ) }}</span>
                                                </td>
                                                <td class="px-0 text-right">
                                                    <span v-if="SalesOrder__currency_id == 2">{{ formatThisNumber( item.Stock.invoiceamount, '0,0.00' ) }}</span>
                                                    <span v-else>{{ formatThisNumber( (item.Stock.invoiceamount / SalesOrder__gstrmrate), '0,0.00' ) }}</span>
                                                </td>
                                                <td class="px-0 text-right">
                                                    <span v-if="SalesOrder__currency_id == 2">{{ formatThisNumber( item.Stock.sellingprice * SalesOrder__gstrmrate, currencyFormatPrecision ) }}</span>
                                                    <span v-else>{{ formatThisNumber( item.Stock.sellingprice, currencyFormatPrecision ) }}</span>
                                                </td>
                                                <td class="px-0 text-right">
                                                    <span v-if="SalesOrder__currency_id == 2">{{ formatThisNumber( item.Stock.invoiceamount * SalesOrder__gstrmrate, currencyFormatPrecision ) }}</span>
                                                    <span v-else>{{ formatThisNumber( item.Stock.invoiceamount, currencyFormatPrecision ) }}</span>
                                                </td>
                                            </tr>
                                            <tr class="table-row" v-if="item.type == 'othercharge'">
                                                <td class="px-0" colspan="4">{{ item.Stock.size }}</td>
                                                <td class="px-0"></td>
                                                <td class="px-0"></td>
                                                <td class="px-0 text-right">
                                                    <span v-if="SalesOrder__currency_id == 2">{{ formatThisNumber( item.Stock.invoiceamount, '0,0.00' ) }}</span>
                                                    <span v-else>{{ formatThisNumber( (item.Stock.invoiceamount / SalesOrder__gstrmrate), '0,0.00' ) }}</span>
                                                </td>
                                                <td class="px-0"></td>
                                                <td class="px-0 text-right">
                                                    <span v-if="SalesOrder__currency_id == 2">{{ formatThisNumber( item.Stock.invoiceamount * SalesOrder__gstrmrate, currencyFormatPrecision ) }}</span>
                                                    <span v-else>{{ formatThisNumber( item.Stock.invoiceamount, currencyFormatPrecision ) }}</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
                                <v-simple-table class="specification-table" dense>
                                    <template v-slot:default>
                                        <tr v-if="SalesOrder__remarks != null || SalesOrder__fsc != null">
                                            <td colspan="10">
                                                <div class="d-flex flex-column">
                                                    <span class="font-weight-bold py-0 my-0">{{ $t('message.remarks') }}:</span>
                                                    <span style="white-space: pre-line" v-if="SalesOrder__remarks != null">{{ SalesOrder__remarks != null ? SalesOrder__remarks.trim() : '' }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="10">
                                                <v-row no-gutters>
                                                    <v-col class="d-flex flex-column col-9" v-if="[7,8].includes(SalesOrder__fsc) == false && SalesOrder__fsc != null">
                                                        <span>{{ $t('message.certCode') + ' : ' +  getCertification(SalesOrder__fsc,'cert_no') }}</span>
                                                        <span>{{ $t('message.expiryDate') + ' : ' + getCertification(SalesOrder__fsc,'cert_expiry_date') }}</span>
                                                    </v-col>
                                                    <v-col class="d-flex flex-column col-9" v-else/>
                                                    <v-col class="d-flex flex-column col-3 align-end">
                                                        <span>{{ $t('message.exchangeRate') +': '+ formatThisNumber(SalesOrder__gstrmrate,'0.0000') }}</span>
                                                        <span>{{ $t('message.date') + ': ' + formatDate(SalesOrder__sodate) }}</span>
                                                    </v-col>
                                                </v-row>
                                            </td>
                                        </tr>
                                        <tr v-if="SalesOrder__gstpercent == 0">
                                            <td class="font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold width-15-pct"></td>
                                            <td class="font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold"></td>
                                            <td class="font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold"></td>
                                            <td class="font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold width-1-pct">
                                                {{ SalesOrderItems__itemsTotalPieces }}
                                            </td>
                                            <td class="pa-0 font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold text-right width-10-pct">
                                                <div v-for="(total,index) in SalesOrderItems__itemsTotalQty" class="text-no-wrap">
                                                    {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                                </div>
                                            </td>
                                            <td class="font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ $t('message.totalDue') }}
                                            </td>
                                            <td class="font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ formatThisNumber( SalesOrderItems__amount, '0,00.00' ) }}
                                            </td>
                                            <td class="font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold width-10-pct"></td>
                                            <td class="font-weight-bold border-top-1 border-bottom-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ formatThisNumber( SalesOrderItems__amountConverted, currencyFormatPrecision ) }}
                                            </td>
                                        </tr>
                                        <tr v-if="SalesOrder__gstpercent != 0">
                                            <td class="font-weight-bold border-top-1 px-0 font-weight-bold width-15-pct"></td>
                                            <td class="font-weight-bold border-top-1 px-0 font-weight-bold"></td>
                                            <td class="font-weight-bold border-top-1 px-0 font-weight-bold"></td>
                                            <td class="font-weight-bold border-top-1 px-0 font-weight-bold width-1-pct">
                                                {{ SalesOrderItems__itemsTotalPieces }}
                                            </td>
                                            <td class="pa-0 font-weight-bold border-top-1 px-0 font-weight-bold text-right width-10-pct">
                                                <div v-for="(total,index) in SalesOrderItems__itemsTotalQty" class="text-no-wrap">
                                                    {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                                </div>
                                            </td>
                                            <td class="font-weight-bold border-top-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ $t('message.totalDue') }}
                                            </td>
                                            <td class="font-weight-bold border-top-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ formatThisNumber( SalesOrderItems__amount, '0,00.00' ) }}
                                            </td>
                                            <td class="font-weight-bold border-top-1 px-0 font-weight-bold width-10-pct"></td>
                                            <td class="font-weight-bold border-top-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ formatThisNumber( SalesOrderItems__amountConverted, currencyFormatPrecision ) }}
                                            </td>
                                        </tr>
                                        <tr v-if="SalesOrder__gstpercent != 0">
                                            <td class="font-weight-bold px-0 font-weight-bold width-15-pct"></td>
                                            <td class="font-weight-bold px-0 font-weight-bold"></td>
                                            <td class="font-weight-bold px-0 font-weight-bold"></td>
                                            <td class="font-weight-bold px-0 font-weight-bold width-1-pct"></td>
                                            <td class="font-weight-bold px-0 font-weight-bold text-right width-10-pct"></td>
                                            <td class="font-weight-bold px-0 font-weight-bold width-10-pct text-right">
                                                {{ Office__taxterm + " " + SalesOrder__gstpercent.toString() + " %" }}
                                            </td>
                                            <td class="font-weight-bold px-0 font-weight-bold width-10-pct text-right">
                                                {{ formatThisNumber(SalesOrderItems__taxAmount,'0,00.00') }}
                                            </td>
                                            <td class="font-weight-bold px-0 font-weight-bold width-10-pct text-right"></td>
                                            <td class="font-weight-bold px-0 font-weight-bold width-10-pct text-right">
                                                {{ formatThisNumber( SalesOrderItems__taxAmountConverted, currencyFormatPrecision ) }}
                                            </td>
                                        </tr>
                                        <tr v-if="SalesOrder__gstpercent != 0">
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold width-15-pct"></td>
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold"></td>
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold"></td>
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold width-1-pct"></td>
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold width-10-pct"></td>
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ $t('message.grandTotal') }}
                                            </td>
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ formatThisNumber(SalesOrderItems__grandTotal,'0,00.00') }}
                                            </td>
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold width-10-pct text-right"></td>
                                            <td class="font-weight-bold border-bottom-1 px-0 font-weight-bold width-10-pct text-right">
                                                {{ formatThisNumber( SalesOrderItems__grandTotalConverted, currencyFormatPrecision ) }}
                                            </td>
                                        </tr>
                                    </template>
                                </v-simple-table>
                                <v-row no-gutters class="pt-2">
                                    <v-col cols="2" class="font-weight-bold">{{ $t('message.pleaseRemitTo') }}:</v-col>
                                    <v-col cols="10">
                                        <v-row no-gutters v-if="Bank__beneficiary != ''">
                                            <v-col class="col-2-5">{{ $t('message.beneficiaryName') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__beneficiary }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__account != ''">
                                            <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__account }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__title != ''">
                                            <v-col class="col-2-5">{{ $t('message.bank') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__title }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__swiftcode != ''">
                                            <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__swiftcode }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__address != ''">
                                            <v-col class="col-2-5">{{ $t('message.bankAddress') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__address + (Bank__country != null ? ', ' + Bank__country : '') }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__agent != ''">
                                            <v-col class="col-2-5">{{ $t('message.agentBank') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__agent }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__agentswiftcode != ''">
                                            <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__agentswiftcode }}</v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-2">
                                    <v-col cols="12" class="font-weight-bold">{{ $t('message.importantNotes') + ':' }}</v-col>
                                    <v-col cols="12" v-for="footerNote in salesOrderFooterNotes" :key="footerNote.id">
                                        <div class="d-flex flex-row align-stretch">
                                            <span class="px-1">-</span>
                                            <span class="footer-note text-wrap">{{ parseFooterNote(footerNote) }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="align-end">
                                    <v-col cols="7" class="d-flex flex-column py-0">
                                        <span class="font-weight-bold">{{ $t('message.confirmedBySeller') }}</span>
                                        <span class="stamp-area text-center pt-2">
                                            <img :src="'/static/img/appic/stamps/' + Office__stamp" height="70px"/>
                                        </span>
                                        <span class="font-weight-bold border-top-1">{{ $t('message.companyStamp') }}</span>
                                    </v-col>
                                    <v-col cols="5" class="d-flex flex-column py-0">
                                        <span class="font-weight-bold ml-3">{{ $t('message.confirmedByBuyer') }}</span>
                                        <span class="stamp-area"></span>
                                        <span class="font-weight-bold border-top-1 ml-3">{{ '(' + $t('message.pleaseSign') + ')' }}</span>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" class="text-center font-weight-bold">{{ $t('message.documentElectronicallyGenerated') }}</v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </v-app>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
import {api} from "Api";
import {formatDate, isNumeric, numberFormat, nl2br, log} from "../../../helpers/helpers";
import VueHtml2pdf from "vue-html2pdf";
import {offices, salesOrderFooterNotes, uofm} from "@/store/modules/appic/constants"

export default {
    name: "PrintProformaInvoiceBlobV2",
    props: ['salesOrderId'],
    components: {VueHtml2pdf},
    data() {
        return {
            appLogo: AppConfig.appSessionLogo,
            certificationsNew: [],
            currencies: [],
            documentTitle: null,
            docType: 'pi',
            htmlToPdfOptions: {
                image: {
                    type: 'jpeg',
                    quality: 0.70
                },
                html2canvas: {
                    scale: 1.5
                }
            },
            loaded: false,
            offices: offices,
            salesOrderFooterNotes: salesOrderFooterNotes,
            uofmDimensions: uofm.dimensions,
            uofmQty: uofm.qty,
            Bank__address: null,
            Bank__agent: null,
            Bank__agentswiftcode: null,
            Bank__country: null,
            Bank__id: null,
            Bank__title: null,
            Bank__account: null,
            Bank__swiftcode: null,
            Bank__beneficiary: null,
            Contact__email: null,
            Contact__hp: null,
            Contact__name: null,
            Contact__prefix: null,
            Contact__tel: null,
            Customer__title: null,
            Customer__address1: null,
            Customer__address2: null,
            Customer__address3: null,
            Customer__city: null,
            Customer__country: null,
            Customer__country_id: null,
            Customer__gstno: null,
            Customer__postcode: null,
            Customer__state: null,
            Customer__taxno_type: null,
            Customer__uscc: null,
            Office__address1: null,
            Office__address2: null,
            Office__city: null,
            Office__companyno: null,
            Office__contracttel: null,
            Office__country: null,
            Office__currency_id: 1,
            Office__invoiceemail: null,
            Office__invoicetel: null,
            Office__postcode: null,
            Office__state: null,
            Office__stamp: null,
            Office__stamp_ws: null,
            Office__title: null,
            Office__website: null,
            Office__taxterm: null,
            Paymentstatement__title: null,
            Salescontact__name: null,
            SalesOrder__currency_id: 1,
            SalesOrder__fsc: 0,
            SalesOrder__gstpercent: 0,
            SalesOrder__gstrmrate: 1,
            SalesOrder__json: {
                delivery_terms: null
            },
            SalesOrder__office_id: null,
            SalesOrder__pickup_date: null,
            SalesOrder__remarks: null,
            SalesOrder__sodate: null,
            SalesOrder__title: '',
            SalesOrderItems: [],
            SalesOrderItems__amount: 0,
            SalesOrderItems__amountConverted: 0,
            SalesOrderItems__grandTotal: 0,
            SalesOrderItems__grandTotalConverted: 0,
            SalesOrderItems__itemsTotalPieces: 0,
            SalesOrderItems__itemsTotalQty: [],
            SalesOrderItems__taxAmount: 0,
            SalesOrderItems__taxAmountConverted: 0
        }
    },
    computed: {
        currencyFormatPrecision () {
            if(this.SalesOrder__currency_id == 2) {
                return this.currencies.find((currency) => currency.Currency.id === this.Office__currency_id)?.Currency?.formatPrecision
            } else {
                return this.currencies.find((currency) => currency.Currency.id === this.SalesOrder__currency_id)?.Currency?.formatPrecision
            }
        },
        footerNoteReplacements (){
            let obj = {}
            if([1,3].includes(this.SalesOrder__office_id)){
                obj.central_bank = 'Bank Negara'
                obj.rate_time = '9am'
                obj.interest_charge_pct = '1.5%'
            } else if(this.SalesOrder__office_id == 21){
                obj.central_bank = 'Bank Indonesia'
                obj.rate_time = '10am'
                obj.interest_charge_pct = '2%'
            } else if(this.SalesOrder__office_id == 20){
                obj.central_bank = 'Bank of Thailand'
                obj.rate_time = '9am'
                obj.interest_charge_pct = '2%'
            } else if(this.SalesOrder__office_id == 22){
                obj.central_bank = null
                obj.rate_time = null
                obj.interest_charge_pct = '2%'
            }
            return obj
        },
    },
    methods: {
        async beforeDownload({html2pdf, options, pdfContent}) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                let reader = new FileReader()
                let _this = this
                reader.readAsDataURL(pdf.output('blob'));
                reader.onloadend = function () {
                    let file = reader.result;
                    api
                        .post('/localsalesorders/pdf', {
                            pdf: {
                                salesorder_no: _this.SalesOrder__title,
                                salesorder_id: _this.salesOrderId,
                                doctype: _this.docType,
                                file: file
                            },
                        })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                _this.$emit('pdf-uploaded', response.data.url)
                            } else {
                                _this.$emit('pdf-not-uploaded')
                            }
                        })
                        .catch(() => {
                            _this.$emit('pdf-not-uploaded')
                        })
                }
            })
        },
        formatDate,
        formatSize (item) {
            let size = [];
            if(item.Stock.thick?.length > 0) size.push(item.Stock.thick + (item.Smeasurement.title?.length > 0 ? " " + item.Smeasurement.title : ''))
            if(item.Stock.width?.length > 0) size.push(item.Stock.width + (item.Wmeasurement.title?.length > 0 ? " " + item.Wmeasurement.title : ''))
            if(item.Stock.length?.length > 0) size.push(item.Stock.length + (item.Lmeasurement.title?.length > 0 ? " " + item.Lmeasurement.title : ''))
            return size.join(' x ')
        },
        formatThisNumber(value, format) {
            return numberFormat(value, format)
        },
        getCertification(certificationId, certificationInfo) {
            let certificationObj = this.certificationsNew.find(c => c.Certification.id == certificationId)
            if(certificationObj){
                return certificationObj?.Certification[certificationInfo]
            }
            return ''
        },
        getCertifications() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/certifications",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getCurrencyCode(currencyId) {
            let code = 'USD'
            const currency = this.currencies.find((currency) => currency.Currency.id == currencyId)
            if(currency) code = currency.Currency.code
            return code
        },
        getCurrencies() {
            return new Promise((resolve, reject) => {
                log('get currencies')
                api
                    .get("/print/currencies",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getItemDescription(item) {
            // return item.Species.title + (item.Productgroup.title ? ', ' + item.Productgroup.title : '') + (item.Stock.fsc != null && item.Stock.fsc != 'None'  ? ', ' + item.Stock.fsc : '')
            return item.Sosalesdescription.title
        },
        getTaxId () {
            let taxId = ''
            if(this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
            if(this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
            return taxId
        },
        loadSalesOrderById ( salesorder_id ) {
            let self = this
            return new Promise((resolve, reject) => {
                api
                    .get("/print/localsalesorders/" + salesorder_id,{
                    })
                    .then(response => {
                        for(let key in response.data.data[0]){
                            if(key == 'SalesOrder__json'){
                                self[key] = JSON.parse(response.data.data[0][key])
                            } else {
                                self[key] = response.data.data[0][key]
                            }
                        }
                        let office = self.offices.find( o => o.Office.id == self.SalesOrder__office_id )
                        self.Office__currency_id = office.Office.currency_id
                        self.Office__taxterm = office.Office.taxterm
                        resolve( salesorder_id )
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        loadSalesItemsBySalesOrder ( salesorder_id ) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/localsalesorders/" + salesorder_id + "/salesitems")
                    .then(response => {
                        this.SalesOrderItems = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        parseFooterNote (footerNote) {
            if(footerNote.replace){
                if(Array.isArray(footerNote.replace)){
                    let note = _.cloneDeep(footerNote.text)
                    footerNote.replace.forEach( replace => {
                        note = note.replace('<<' + replace + '>>', this.footerNoteReplacements[replace])
                    })
                    return note
                } else {
                    if (this.footerNoteReplacements[footerNote.replace] != null) {
                        return footerNote.text.replace('<<' + footerNote.replace + '>>', this.footerNoteReplacements[footerNote.replace])
                    }
                }
            } else {
                return footerNote.text
            }
        },
        updateTotals () {
            return new Promise((resolve, reject) => {
                try {
                    let amount = 0, amountConverted = 0, pieces = 0, quantities = [], fsc = 0
                    this.SalesOrderItems.forEach((item) => {
                        let currency = null
                        let precision = 2
                        if( this.SalesOrder__currency_id != 2 ) {
                            currency = this.currencies.find(c => c.Currency.id == this.SalesOrder__currency_id)
                        } else {
                            currency = this.currencies.find(c => c.Currency.id == this.Office__currency_id)
                        }
                        if(currency) precision = currency.Currency.decimals
                        if( this.SalesOrder__currency_id == 2 ) {
                            amount += isNumeric(item.Stock.amount) ? parseFloat(item.Stock.amount) : 0
                            amountConverted += isNumeric(item.Stock.amount) ? parseFloat((item.Stock.amount * this.SalesOrder__gstrmrate).toFixed(precision)) : 0
                        } else {
                            amount += isNumeric(item.Stock.amount) ? parseFloat((item.Stock.amount / this.SalesOrder__gstrmrate).toFixed(precision)) : 0
                            amountConverted += isNumeric(item.Stock.amount) ? parseFloat((item.Stock.amount).toFixed(precision)) : 0
                        }
                        pieces += isNumeric(item.Stock.piece) ? parseInt(item.Stock.piece) : 0
                        if(item.type == 'default') {
                            let idx = quantities.findIndex(t => t.Total.uofm == item.Soldmeasurement.title);
                            if (idx !== -1) {
                                quantities[idx]['Total']['qty'] += isNumeric(item.Stock.volume) ? parseFloat(item.Stock.volume) : 0;
                            } else {
                                let obj = {'Total': {}}
                                obj['Total']['uofm'] = item.Soldmeasurement.title;
                                obj['Total']['qty'] = isNumeric(item.Stock.volume) ? parseFloat(item.Stock.volume) : 0;
                                quantities.push(obj)
                            }
                        }
                    })
                    this.SalesOrderItems__amount = amount
                    this.SalesOrderItems__amountConverted = amountConverted
                    this.SalesOrderItems__taxAmount = amount * (this.SalesOrder__gstpercent/100)
                    this.SalesOrderItems__taxAmountConverted = amountConverted * (this.SalesOrder__gstpercent/100)
                    this.SalesOrderItems__grandTotal = amount + this.SalesOrderItems__taxAmount
                    this.SalesOrderItems__grandTotalConverted = amountConverted + this.SalesOrderItems__taxAmountConverted
                    this.SalesOrderItems__itemsTotalPieces = pieces
                    this.SalesOrderItems__itemsTotalQty = quantities
                    resolve('done')
                } catch(error) {
                    reject(error)
                }
            })
        },
        uofmPrecision (uofm) {
            return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
        }
    },
    created() {
        this.getCertifications()
            .then((certifications) => {
                this.certificationsNew = certifications
                this.getCurrencies()
                    .then((currencies) => {
                        this.currencies = currencies
                        this.loadSalesOrderById(this.salesOrderId)
                            .then(()=>{
                                this.loadSalesItemsBySalesOrder(this.salesOrderId)
                                    .then((response)=>{
                                        this.updateTotals()
                                            .then(()=>{
                                                this.loaded = true
                                                this.$refs.html2Pdf.generatePdf()
                                            })
                                            .catch(() => {
                                                this.$emit('pdf-not-uploaded')
                                            })
                                    })
                                    .catch(() => {
                                        this.$emit('pdf-not-uploaded')
                                    })
                            })
                            .catch(() => {
                                this.$emit('pdf-not-uploaded')
                            })
                    })
                    .catch(() => {
                        this.$emit('pdf-not-uploaded')
                    })
            })
            .catch(() => {
                this.$emit('pdf-not-uploaded')
            })
    }

}
</script>

<style scoped>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.document-caption {
    font-size: 16px !important;
    line-height: 18px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 20pt !important;
    line-height: 25px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10.5pt !important;
}
.specification-table table tr td {
    font-family:'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
}
.specification-table table tr.table-header td {
    font-family:'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 15px !important;
}
.specification-table table tr.table-row-main td {
    font-family:'AppicArial' !important;
    line-height:20px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 20px !important;
}
.specification-table table tr.table-row-sub td {
    font-family:'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:0px !important;
    height: 15px !important;
}
.stamp-area {
    height: 90px;
}
.paper-a4 {
    height: 1065px;
    margin-left: 75px;
    margin-top: 35px;
    background-color: #FFFFFF !important;
    color: #000000 !important;
}
.v-application{
    font-family:'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
}
.v-application{
    font-family: 'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
* {
    text-rendering: geometricprecision !important;
}
</style>